exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-windex-js": () => import("./../../../src/pages/windex.js" /* webpackChunkName: "component---src-pages-windex-js" */),
  "component---src-templates-archive-terms-jsx": () => import("./../../../src/templates/archive-terms.jsx" /* webpackChunkName: "component---src-templates-archive-terms-jsx" */),
  "component---src-templates-single-customer-jsx": () => import("./../../../src/templates/single-customer.jsx" /* webpackChunkName: "component---src-templates-single-customer-jsx" */),
  "component---src-templates-single-job-jsx": () => import("./../../../src/templates/single-job.jsx" /* webpackChunkName: "component---src-templates-single-job-jsx" */),
  "component---src-templates-single-page-jsx": () => import("./../../../src/templates/single-page.jsx" /* webpackChunkName: "component---src-templates-single-page-jsx" */),
  "component---src-templates-single-post-jsx": () => import("./../../../src/templates/single-post.jsx" /* webpackChunkName: "component---src-templates-single-post-jsx" */),
  "component---src-templates-single-tag-jsx": () => import("./../../../src/templates/single-tag.jsx" /* webpackChunkName: "component---src-templates-single-tag-jsx" */),
  "component---src-templates-single-term-jsx": () => import("./../../../src/templates/single-term.jsx" /* webpackChunkName: "component---src-templates-single-term-jsx" */)
}

